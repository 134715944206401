import {
    Box,
    createTheme, Stack,
    ThemeProvider
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import './App.css';
import {Config} from "./config/Config";
// @ts-ignore
import AdSense from 'react-adsense';

interface RawPrice {
    price: number,
    date: Date,
}

function App() {
    const [lastPrice, setLastPrice] = useState<RawPrice>();
    const [highestPrice, setHighestPrice] = useState<RawPrice>({price: 0, date: new Date()});
    const [loading, setLoading] = useState<boolean>(true);
    const [height, setHeight] = useState<number>(0);

    const doRequest = () => {
        fetch(`${Config.HOST}/price`,
            {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                mode: 'cors',
            }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        }).then((data) => {
            if (data.price != null) {
                setLastPrice(data);
                if(lastPrice?.price != null && data.price > lastPrice.price) {
                    setHighestPrice(data);
                }
                setLoading(false);
            }
        }).catch((error) => {
            console.error('could not fetch remote data', error);
        });
    }

    const updateHighest = () => {
        fetch(`${Config.HOST}/max`,
            {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                mode: 'cors',
            }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
        }).then((data) => {
            if (data.price != null) {
                setHighestPrice(data);
                setLoading(false);
            }
        }).catch((error) => {
            console.error('could not fetch remote data', error);
        });
    }

    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#e65100',
                light: '#ff833a',
                dark: '#ac1900',
                contrastText: '#000000',
            },
            secondary: {
                main: '#2e7d32',
                light: '#60ad5e',
                dark: '#005005',
                contrastText: '#ffffff',
            }
        },
        typography: {
            fontFamily: [
                'Arial',
            ].join(','),
            body1: {
                fontWeight: 700,
            },
        },
    });

    useEffect(() => {
        if (loading) {
            doRequest();
            updateHighest();
        }

        function handleResize() {
            setHeight(window.outerHeight);
        }

        const interval = setInterval(() => {
            doRequest();
        }, Config.INTERVAL_TIME);

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
            clearInterval(interval);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: height,
                width: '100%',
                backgroundColor: 'black',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    backgroundColor: theme.palette.primary.main,
                    marginLeft: '15%',
                    marginRight: '15%',
                    height: '20%',
                    padding: 2,
                    borderRadius: 5,
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        Do not go AFK! You might end up losing:
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <Stack sx={{ textAlign: 'center'}}>
                            <Box sx={{fontWeight: 800}}>
                                {lastPrice?.price != null ? (lastPrice.price * Config.LOST).toFixed(2) : '0'} €
                            </Box>
                            <Box sx={{color: theme.palette.primary.light}}>
                                {highestPrice?.price != null ? (highestPrice.price * Config.LOST).toFixed(2) : '0'} €
                            </Box>
                            <Box sx={{color: theme.palette.primary.light, fontSize: 12}}>
                                ( max since running this site )
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        worth of crypto!
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default App;
